@import './colors';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'react-tooltip/dist/react-tooltip.css';

html,
body,
#root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    background: white;
    color: $text;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: $primary;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

* {
    outline: none !important;
}

// Classes for react-tooltip
.react-tooltip {
    padding: 2px 8px !important;
    z-index: 1 !important;
    background: #394050 !important;
    border-radius: 5px !important;
}