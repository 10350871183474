// Application colours
$text: #111827;
$text-secondary: #757575;
$hint: #9e9e9e;
$border-color: #e8e8e8;
$primary: #406CCF;
$red: #c7474a;
$green: #82c64e;
$yellow: #ffc836;
$orange: #ff9c2e;
$nav: #1e2837